/*============================================================================
Policy Pages
=============================================================================*/
.shopify-policy__container {
  max-width: 1170px !important;
  margin: 0 auto 40px !important;
  padding: 0 !important;
}
.shopify-policy__title {
  text-align: left !important;
}
